import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { CommonMetaHeader } from '../custom/components/common-meta-header';
import ContactNum from '../custom/components/contact-num';
import { JsonLDTag, JsonLDType } from '../custom/components/json-ld-tag';
import PageBackBtn from '../custom/components/page-back-btn';
import TwoPassRender from '../custom/components/two-pass-render';
import { CustomGtagCategory } from '../custom/constants/gtag-category';

export const Head = () => {
  return (
    <>
      <CommonMetaHeader />
      <JsonLDTag
        type={JsonLDType.BreadcrumbList}
        breadcrumb={[{ name: 'Singapore Funeral Directory', url: 'https://singaporefuneraldirectory.org' }]}
      />
    </>
  );
};

const FamilyPage = () => {
  return (
    <TwoPassRender>
      <Box padding={1}>
        <Box sx={{ maxWidth: 800, margin: 'auto' }}>
          <Box mt={3}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                WebkitAlignSelf: 'center',
              }}
            >
              For Family
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                WebkitAlignSelf: 'center',
              }}
            >
              How to use Singapore Funeral Directory
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography component="div">
              <ol
                style={{
                  maxWidth: 800,
                  margin: 'auto',
                  paddingInlineStart: '20px',
                }}
              >
                <li>
                  Go to{' '}
                  <a
                    href="https://singaporefuneraldirectory.org/"
                    target="_blank"
                    rel="noopener"
                    aria-label="Open singaporefuneraldirectory.org"
                  >
                    singaporefuneraldirectory.org
                  </a>{' '}
                  or Google search for Singapore Funeral Directory
                </li>
                <li>Select the religion</li>
                <li>
                  Call/WhatsApp any Funeral Services Provider/Funeral Director.
                  Always available at 24/7
                </li>
              </ol>
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography component="div">
              Singapore Funeral Directory understands what families are going
              through. This is why the journey to engage a Funeral Director at
              Singapore Funeral Directory is designed to be friendly with
              minimum steps.
            </Typography>
          </Box>

          <Box mt={6}>
            <Typography component="div">
              <span aria-hidden style={{ display: 'inline-block' }}>
                Call&nbsp;
              </span>
              <span style={{ display: 'inline-block' }}>
                <ContactNum
                  phoneNumber="92366269"
                  phoneNumGtagCategory={CustomGtagCategory.SFD_Phone_Link}
                ></ContactNum>
              </span>
              <span aria-hidden style={{ display: 'inline' }}>
                &nbsp;to share your feedback or compliment the funeral services
                provider and/or funeral director of Singapore Funeral Directory.
              </span>
            </Typography>
          </Box>
        </Box>

        <Box mt={4}>
          <Grid container sx={{ maxWidth: 800, margin: 'auto' }}>
            <PageBackBtn gtagCategory={CustomGtagCategory.Family_Back} />
          </Grid>
        </Box>
      </Box>
    </TwoPassRender>
  );
};

export default FamilyPage;
